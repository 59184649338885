<template>
  <section
    :key="selected_bot.id"
    :class="{
      databot_live: true,
      dlive_item3: show_client_info,
    }"
  >
    <div
      :class="{
        'item item-1 pb-0': true,
        hidden: showMobileViewChat || show_client_info,
        showMobileMode: !showMobileViewChat && !show_client_info,
      }"
    >
      <ChatCards
        :conversations="searchConversations"
        :tags="tags"
        :lead="lead"
        :isLoadingConversations="isLoadingConversations"
        :toArchive="toArchive"
        :itemsPerPage="itemsPerPage"
        :page="page"
        :pages="pages"
        @onSelectedConversation="selectConversation"
        @onSelectedState="initialize()"
        @onAddCategoryFilter="onAddCategoryFilter"
        @sendShowArchived="showArchived = $event"
        @onSearchChat="onSearchChat($event, 1)"
        @changePage="page = $event"
        @changeItemsPerPage="itemsPerPage = $event"
        @sendToArchive="toArchive = !toArchive"
        @closeViewChat="selectedConversation = null"
      />
    </div>
    <div
      :class="{
        'item item-2 fadeItemMobile': true,
        hidden: !showMobileViewChat,
        showMobileMode: showMobileViewChat,
      }"
    >
      <ViewChat
        :isLoadingMessages="isLoadingMessages"
        :modal="selectedConversation"
        :lead="lead"
        :selectedChat="selectedConversation"
        :messages="$store.state.conversationsModule.messages"
        @sendConnectAgent="
          $event
            ? connectAgent(selectedConversation)
            : disconnectAgent(selectedConversation)
        "
        @sendMessage="sendMessage($event, 'Agente', 'text')"
        @sendMessageFile="
          sendMessage('', 'Agente', $event.type, { url: $event.url })
        "
        @sendMessageAudio="
          sendMessage('', 'Agente', 'audio', { url: $event.url })
        "
        @sendDeriveAgent="showDeriveAgents = $event"
        @closeMobile="showMobileViewChat = false"
        @sendToArchive="toArchive = !toArchive"
      />
    </div>
    <div
      v-show="show_client_info"
      :class="{
        'item item-3 fadeItemMobile': true,
        hidden: !show_client_info,
        showMobileMode: show_client_info,
      }"
    >
      <InfoChat
        :modal="lead"
        :leadId="lead.id"
        :isDatabotLive3="true"
        :conversation="selectedConversation"
        :files="sessions[0]?.session_files"
        @sendDeriveAgent="showDeriveAgents = $event"
      />
    </div>
    <DesarchivarConversacion
      v-if="showArchived"
      :showModal="showArchived"
      @closeModal="showArchived = false"
      @updateChats="updateChats"
    />
    <DerivarAgente
      :agents="agents"
      :showModal="showDeriveAgents"
      @closeModal="showDeriveAgents = false"
      @derivar="shuntAgent"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import socket from '@/plugins/sockets';
import ChatCards from './components/chat_cards';
import ViewChat from './components/view_chat';
import InfoChat from './components/info_chat';
import DesarchivarConversacion from './components/DesarchivarConversacion.vue';
import DerivarAgente from './components/DerivarAgente';
import dashboard_api from '@/dashboard_api.js';
import { scrollBottom } from '@/utils/utils.js';
import agentActivitiesService from '@/services/api_micro_conversations/agentActivities.js';

export default {
  components: {
    ChatCards,
    ViewChat,
    InfoChat,
    DesarchivarConversacion,
    DerivarAgente,
  },
  data() {
    return {
      search: '',
      conversations: [],
      sessions: [],
      selectedConversation: null,
      showMobileViewChat: false,
      lead: { id: null },
      isLoadingMessages: false,
      showDeriveAgents: false,
      agents: [],
      isLoadingConversations: false,
      tags: [],
      tagsFilter: [],
      toArchive: false,
      showArchived: false,
      itemsPerPage: '15',
      page: 1,
    };
  },
  async mounted() {
    await this.getAgents();
    await this.initialize();
  },
  computed: {
    ...mapState([
      'user',
      'roomToken',
      'selected_bot',
      'show_client_info',
      'push_data',
      'selectedChat',
      'activeChatRooms',
    ]),
    agentAccount() {
      return this.$store.state.agentAccount;
    },
    searchConversations() {
      return this.filteredConversations;
    },
    filteredConversations() {
      return this.$store.getters['conversationsModule/getSortedConversations']
        .filter(
          (el) =>
            el.state === this.$store.state.conversationsModule.selectedState,
        )
        .filter(
          (el) =>
            this.agentAccount &&
            ((this.agentAccount.category &&
              this.agentAccount.category.includes(el.category)) ||
              !this.agentAccount.hasOwnProperty('category') ||
              (!el.category && el.category === 'general')),
        )
        .filter((el) => !el.archived)
        .reduce((acc, conversation) => {
          // TODO: remove this reduce and find another way to prevent duplicated cards
          if (!acc.some((item) => item.id === conversation.id)) {
            acc.push(conversation);
          }
          return acc;
        }, []);
    },
    messages() {
      return this.$store.state.conversationsModule.messages;
    },
    selectedState() {
      return this.$store.state.conversationsModule.selectedState;
    },
    pages() {
      return this.$store.getters[
        'conversationsModule/getTotalPagesConversation'
      ];
    },
  },
  watch: {
    async page(val) {
      if (this.search) {
        await this.onSearchChat(this.search, val);
      } else {
        await this.initialize({ page: val });
      }
    },
    async itemsPerPage() {
      if (this.search) {
        await this.onSearchChat(this.search, this.page);
      } else {
        await this.initialize({ page: this.page });
      }
    },
  },
  methods: {
    ...mapMutations(['setAgentAccount']),

    // imTyping(message) {
    //   // el primer if hace que se elimine el gift de escribiendo si es que no hay mensajes escribiendose
    //   if (message.length === 0 && this.typing) {
    //     this.typing = false;
    //     return this.typingEmit();
    //   }
    //   if (!this.typing) {
    //     this.typing = true;
    //     this.typingEmit();
    //   }
    // },
    // typingEmit() {
    //   socket.emit('IM_TYPING', {
    //     name: 'agente',
    //     typing_status: this.typing,
    //     room: this.roomToken,
    //   });
    // },
    async onSearchChat(searchChat, page) {
      this.search = searchChat;
      this.page = page;
      if (this.search) {
        await this.$store.dispatch('conversationsModule/searchConversations', {
          bot_id: this.selected_bot.id,
          search_text: this.search,
          page: this.page,
          items: this.itemsPerPage,
        });
      } else {
        await this.initialize();
      }
    },
    async initialize({ page = 1, filters = {} } = {}) {
      try {
        this.isLoadingConversations = true;
        let payload = {
          botId: this.selected_bot.id,
          agentId: this.user.id,
          items: this.itemsPerPage,
          page,
          filters: {
            ...filters,
            bot_id: this.selected_bot.id,
            archived: false,
          },
        };
        if (this.selectedState) {
          payload.filters = {
            ...payload.filters,
            state: this.selectedState,
          };
        }
        // add tags for filter
        if (this.tagsFilter) {
          payload.filters = {
            ...payload.filters,
            tags_lead: this.tagsFilter,
          };
        }
        // just send agent_id if conversation was taken
        if (this.selectedState === 'taken') {
          payload.filters = {
            ...payload.filters,
            agent_id: this.user.id,
          };
        }
        // add category for filter
        if (this.agentAccount?.category) {
          payload.filters = {
            ...payload.filters,
            category: this.agentAccount?.category,
          };
        }
        await Promise.all([
          this.$store.dispatch('conversationsModule/filterConversations', {
            ...payload,
          }),
          this.$store.dispatch('conversationsModule/countStates', {
            botId: this.selected_bot.id,
            userId: this.user.id,
            category: this.agentAccount?.category,
            tagsLead: this.tagsFilter,
          }),
        ]);
        await this.getTags();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async onAddCategoryFilter(val) {
      console.log('val->', val);
      this.tagsFilter = val;
      await this.initialize();
    },
    async selectConversation(conversation) {
      this.isLoadingMessages = true;
      this.showMobileViewChat = true;
      try {
        // emit event to user chatbox to check if its currently connected
        socket.emit('CHECK_USER_ONLINE_STATUS', {
          room_token: conversation.room_token,
        });
        this.$store.commit('conversationsModule/setMessages', []);
        this.selectedConversation = conversation;
        // socket to reset pending messages counter to all users
        if (conversation.pending_messages_count > 0) {
          conversation.pending_messages_count = 0;
          socket.emit('RESTART_PENDING_MESSAGES', {
            conversationId: conversation.id,
          });
          // update conversation in database
          this.$store.dispatch('conversationsModule/update', {
            id: conversation.id,
            data: { pending_messages_count: 0 }, // 2 means taken
            hasToUpdateStore: false,
          });
        }
        // in case has lead, get it
        if (conversation.lead_id) {
          this.lead = await this.$store.dispatch('leadsModule/listOne', {
            id: conversation.lead_id,
          });
        } else {
          this.lead = {
            id: null,
            name: conversation.lead_name || '',
            city: conversation.custom?.city,
            company: conversation.custom?.company,
            email: conversation.custom?.email,
          };
        }
        this.sessions = await this.getSessions(conversation.id);
        console.log('sessions->', this.sessions);
        // combine all messages from session_logs field from sessions
        const messages = this.sessions
          .reduce((acc, session) => {
            return acc.concat(
              session.session_logs.map((el) => ({ ...el, from: el.sender })), // add from field to each message
            );
          }, [])
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        this.$store.commit('conversationsModule/setMessages', messages);
        this.$store.commit(
          'conversationsModule/setSelectedConversation',
          conversation,
        );
        conversation.pending_messages_count = 0;
        // update conversation in store
        this.$store.commit('conversationsModule/update', {
          id: conversation.id,
          data: conversation, // 2 means taken
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingMessages = false;
        await this.$nextTick();
        const objDiv = document.getElementById('chat-container'); // es el id del contenedor del chat
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    },
    async getSessions(conversationId) {
      const sessions = await this.$store.dispatch(
        'sessionsModule/byConversationId',
        {
          conversationId,
        },
      );
      return sessions;
    },
    async sendMessage(text, from = 'Agente', type = 'text', payload = {}) {
      const { url, size, isRatingFromAgent } = payload;
      // persist message in db
      let item = {
        from,
        text,
        message_type: type,
        conversation_id: this.selectedConversation.id,
        session_id: this.sessions[this.sessions?.length - 1].id,
        temporal_id: Date.now(), // temporal id to identify message in frontend and allow update with mid from socket
        userId: this.user.id,
        agent_id: this.user.id,
      };
      if (payload) {
        item['payload'] = payload;
      }
      socket.emit('AGENT_MESSAGE', {
        conversation: this.selectedConversation,
        ...item,
      });
      if (url) {
        // Refresh info_chat files
        this.sessions = await this.getSessions(this.selectedConversation.id);
      }
      // just create in front in case of web channel
      if (this.selectedConversation.channel === 'web') {
        this.$store.dispatch('sessionLogsModule/create', item);
        // messages from backend platforms come fron sockets
      }
      this.$store.commit('conversationsModule/addMessage', item);
      scrollBottom();
    },
    connectAgent(conversation) {
      this.clearChat();
      console.log('CONECTANDO AGENTE...');
      this.$swal({
        title: 'Estás por ingresar a esta conversación',
        text: 'El chatbot quedará inactivo para este cliente, por favor pide los datos que falten.',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Iniciar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.changeConversationState(conversation, 'taken');
          await this.$store.dispatch('conversationsModule/update', {
            id: conversation.id,
            data: { state: 2, agent_id: this.user.id }, // 2 means taken
          });
          // create agent activity
          let agentActivityId;
          agentActivitiesService
            .create({
              activity: {
                name: this.user.name,
                calification: 0,
                transfered: false,
                session_id: this.sessions[0].id,
                agent_id: this.user.id,
              },
            })
            .then((res) => {
              agentActivityId = res.data.id;
              // update session state (must be created after agent activity)
              this.$store.dispatch('sessionsModule/update', {
                id: this.sessions[0].id,
                data: { session: { state: 2, agent: this.user.name } },
              });
            });

          socket.emit('CONNECT_AGENT', {
            conversation,
            user: this.user,
            botId: this.selected_bot.id,
            agentActivityId,
          });
          // update conversation state to all users
          socket.emit('UPDATE_CONVERSATION_CARD', {
            ...conversation,
            state: 'taken',
          });
          // send alert to client
          console.log('SENDING MESSAGE...');
          this.sendMessage(
            'Ahora estas conversando con un agente real',
            'Watson',
            'alert',
          );
          // go to new state section
          this.$store.commit('conversationsModule/setSelectedState', 'taken');
          await this.initialize();
        }
      });
    },
    disconnectAgent(conversation) {
      this.$swal({
        title: '¿Terminar conversación?',
        icon: 'info',
        showConfirmButton: true,
        confirmButtonText: 'Terminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.changeConversationState(conversation, 'active');
          await this.$store.dispatch('conversationsModule/update', {
            id: conversation.id,
            data: {
              state: conversation.channel === 'web' ? 3 : 0,
              agent_id: 0,
            }, // 0 means active
          });
          // update session state
          await this.$store.dispatch('sessionsModule/update', {
            id: this.sessions[0].id,
            data: { state: 3 }, // 3 means closed
          });
          socket.emit('DISCONNECT_AGENT', {
            conversation,
            user: this.user,
            botId: this.selected_bot.id,
          });
          // update conversation state to all users
          socket.emit('UPDATE_CONVERSATION_CARD', {
            ...conversation,
            state: 'active',
          });
          this.sendMessage(
            'El agente terminó la conversación',
            'Watson',
            'alert',
            { isRatingFromAgent: true },
          );
          this.sendMessage(
            '¿Cómo calificarías al agente que te atendió?',
            'Watson',
            'rating',
            { isRatingFromAgent: true },
          );
          this.selectedConversation = null;
          this.$store.commit(
            'conversationsModule/setSelectedConversation',
            null,
          );
          await this.initialize();
        }
      });
    },
    changeConversationState(conversation, newState) {
      const previousState = conversation.state;
      conversation.state = newState;
      this.$store.state.conversationsModule.countersByState[previousState] -= 1;
      this.$store.state.conversationsModule.countersByState[newState] += 1;
      // remove conversation
    },
    async getAgents() {
      this.agents = (
        await dashboard_api.get(`/userbot/getallAgent/${this.selected_bot.id}`)
      ).data;
      // console.log("agents 1->", this.agents);
      let agentAccount = this.agents.find((el) => el.user_id == this.user.id);
      this.setAgentAccount(agentAccount);
      // console.log("agentAccount->", this.agentAccount);
    },
    async shuntAgent(agent, transfered, transfer_reason, selectedArea) {
      // console.log('1 selected_agent->', agent);
      // console.log('2 transfered->', transfered);
      // console.log('3 comment->', transfer_reason);
      // console.log('4 selectedArea->', selectedArea);
      this.$swal.showLoading();

      // dashboard_api.post('/chat_rooms/save_agent_history', {
      //   id: this.roomToken,
      //   agent_id: agent.user_id,
      // });
      await this.$store.dispatch('conversationsModule/update', {
        id: this.selectedConversation.id,
        data: {
          category: selectedArea,
          agent_id: agent.user_id,
        },
      });
      if (this.lead.id) {
        dashboard_api
          .put(`/relief/upagent/${this.lead.id}`, {
            agent: agent.user.name,
          })
          .then((result) => {
            console.log('Cambio de Agente Asignado->', result.data);

            // Actualizando cards
            // let test = this.activeChatRooms.filter(el => {el.agent_id != this.user.id && el.room_token == this.roomToken});
            // this.setActiveChatRooms(test);
          })
          .catch((err) => {
            console.log('err->', err);
          });
      }

      // Generar nota con el comentario de transferencia
      this.saveNewNote(agent.user.name, transfer_reason);
      // socket to send conversation to new agent
      socket.emit('DERIVE_CONVERSATION', {
        conversation: this.selectedConversation,
        target_agent: agent,
        target_user_id: agent.user_id,
        transfer_reason,
        transfered,
        category: selectedArea,
      });
      // Agente transferido
      // this.saveAgentHistory(agent, transfered, transfer_reason);
      this.$swal({
        icon: 'success',
        title: 'Listo',
        timer: 1000,
        showConfirmButton: false,
      });
      this.$store.dispatch('notificationsModule/transferConversation', {
        from: this.user,
        to: agent,
      });
      // remove selected conversation
      this.$store.commit('conversationsModule/setSelectedConversation', null);
      this.selectedConversation = null;
      await this.initialize();
    },
    saveNewNote(title, body) {
      dashboard_api
        .post('/lead_notes', {
          title: `Para ${title}`,
          body,
          lead_id: this.lead.id,
        })
        .then((result) => {
          console.log('nota creada', result);
        });
    },
    clearChat() {
      this.lead = { id: null };
    },
    async getTags() {
      dashboard_api
        .get(`/label_leads/by_bot/${this.selected_bot.id}`)
        .then((response) => {
          console.log('getTags response = ', response.data);
          this.tags = response.data;
        })
        .catch((error) => console.log(error));
    },
    updateChats(chat) {
      this.toUnArchivedChat = chat;
      this.sendPushDataKey = !this.sendPushDataKey;
    },
  },
};
</script>

<style lang="scss" scoped>
.databot_live {
  position: relative;
  display: grid;
  grid-template-columns: 460px calc(100% - 460px);
  background-color: #fff;
  max-height: 100%;
  overflow: hidden;
  height: calc(100vh - 80px);

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .item-1 {
    position: inherit;
    padding: 1.5rem 1rem;
    border-right: 1px solid #e8e8e8;
    background-color: #fff;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;
    z-index: 1;

    @media (max-width: 1600px) {
      padding: 1.5rem 0.25rem;
    }
    @media (max-width: 1279px) {
      padding: 1.5rem 1rem;
    }
    @media (max-width: 1024px) {
      border-right: 0;
    }
  }
  .item-2 {
    position: inherit;
    padding: 0;
    background-color: #fff;
    border-right: 1px solid #e8e8e8;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;

    @media (max-width: 1279px) {
      z-index: 2;
    }
    @media (max-width: 1024px) {
      border-right: 0;
    }
  }
  .item-3 {
    position: inherit;
    padding: 1.5rem 0;

    @media (max-width: 1600px) {
      padding: 1.5rem 0.25rem;
    }
    @media (max-width: 1279px) {
      z-index: 3;
    }
  }
  .fadeItemMobile {
    animation-name: test;
    animation-duration: 0.25s;
  }
  .fadeLeaveItemMobile {
    animation-name: test2;
    animation-duration: 0.25s;
  }
  .showMobileMode {
    @media (max-width: 1279px) {
      display: inline-block !important;
    }
  }
  .hidden {
    @media (max-width: 1279px) {
      display: none !important;
    }
  }
}
.dlive_item3 {
  grid-template-columns: 460px 2fr 460px;

  @media (max-width: 1600px) {
    grid-template-columns: 380px 2fr 380px;
  }
  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
@keyframes test {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes test2 {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}
</style>
