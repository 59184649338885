<template>
  <section>
    <div v-for="(group, groupIndex) in groupedMessages" :key="groupIndex">
      <div
        v-for="(message, messageIndex) in group.grouped"
        :key="messageIndex"
        :class="{
          'd-flex justify-content-end':
            group.from !== 'Cliente' && message.message_type !== 'alert',
        }"
      >
        <div
          :class="{
            'from-client': group.from === 'Cliente',
            'from-agent': group.from != 'Cliente',
            rich_element: message.message_type == 'audio',
          }"
        >
          <div
            :class="{
              'd-flex align-items-end': true,
              'justify-content-end': group.from !== 'Cliente',
            }"
          >
            <template v-if="group.from === 'Cliente'">
              <img
                v-if="
                  message.text ===
                    group.grouped[group.grouped.length - 1].text &&
                  conversation.channel === 'instagram' &&
                  conversationMetadata.profile_pic
                "
                :src="conversationMetadata.profile_pic"
                alt=""
                class="mr-2 msg-profile"
              />
              <span
                v-else-if="
                  message.text === group.grouped[group.grouped.length - 1].text
                "
                :class="{
                  'profile-client desktop-xs mr-3': true,
                  'profile-wsp': conversation.channel == 'Whatsapp Bot',
                  'profile-instagram': conversation.channel == 'instagram',
                }"
                >{{
                  (conversation.lead_name && conversation.lead_name[0]) || 'C'
                }}</span
              >
              <span v-else class="space-left"></span>
            </template>
            <div
              :class="{ 'message-inner': validateFormatMessage(group, message) }"
              @mouseover="showIcon = messageIndex"
              @mouseleave="showIcon = -1"
            >
              <div v-if="message.reply_to">
                Usuario respondió a:
                <component
                  :is="
                    getDynamicComponent(
                      getMessageByPlatformId(message.reply_to)?.message_type,
                    )
                  "
                  :message="getMessageByPlatformId(message.reply_to)"
                  :is_reply="true"
                ></component>
                <div class="horizontal-line"></div>
              </div>
              <MessageText
                v-if="!message.message_type || message.message_type == 'text'"
                :message="message"
              />
              <MessageTemplateWhatsApp
                v-if="
                  message.message_type == 'template_wsp' &&
                  message.payload &&
                  message.payload.template
                "
                :message="message"
              />
              <MessageOption
                v-if="message.message_type == 'option'"
                :message="message"
              />
              <MessageAudio
                v-if="message.message_type == 'audio'"
                :message="message"
              />
              <MessageVideo
                v-if="message.message_type == 'video'"
                :message="message"
              />
              <MessageSticker
                v-if="message.message_type == 'sticker'"
                :message="message"
              />
              <MessageFile
                v-if="message.message_type == 'file'"
                :message="message"
              />
              <MessageImage
                v-if="message.message_type == 'image'"
                :message="message"
              />
              <MessageCarousel
                v-if="message.message_type == 'carousel'"
                :message="message"
              />
              <MessageRating
                v-if="message.message_type == 'rating'"
                :message="message"
              />
              <MessageLocation
                v-if="message.message_type == 'location'"
                :message="message"
              />
              <p
                v-if="!message.text.includes('Ahora estas conversando con')"
                class="text_xs mt-2"
              >
                {{ messageTime(message.created_at) }}
              </p>
              <!-- Icon that shows on hover -->
              <!-- <div
                v-if="showIcon === messageIndex"
                class="icon-row"
                @click="showMenuIndex = messageIndex"
              >
                ⤵️
              </div> -->
              <!-- Menu that shows on icon click -->
              <!-- <div
                v-if="showMenuIndex === messageIndex && showMenuIndex > -1"
                class="message-menu"
              >
                <ul>
                  <li @click="replyToMessage(message)">Responder</li>
                </ul>
              </div> -->
            </div>
            <template
              v-if="
                group.from != 'Cliente' &&
                !message.text.includes('Ahora estas conversando con')
              "
            >
              <img
                v-if="
                  message.created_at === group.grouped[group.grouped.length - 1].created_at
                "
                class="profile-bot desktop-xs ml-3"
                :src="
                  group.from == 'Watson'
                    ? getImgBot
                    : user.image || 'img/brand/0-default.svg'
                "
                alt=""
              />
              <span v-else class="space-right"></span>
            </template>
          </div>
        </div>
        <MessageAlert
          v-if="message.message_type == 'alert'"
          :message="message"
          :user="user"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { formatDateRelative } from '@/utils/utils';
import MessageText from '@/views/V2/Conversations/components/chatElements/MessageText.vue';
import MessageAlert from '@/views/V2/Conversations/components/chatElements/MessageAlert.vue';
import MessageCarousel from '@/views/V2/Conversations/components/chatElements/MessageCarousel.vue';
import MessageFile from '@/views/V2/Conversations/components/chatElements/MessageFile.vue';
import MessageImage from '@/views/V2/Conversations/components/chatElements/MessageImage.vue';
import MessageOption from '@/views/V2/Conversations/components/chatElements/MessageOption.vue';
import MessageAudio from '@/views/V2/Conversations/components/chatElements/MessageAudio.vue';
import MessageVideo from '@/views/V2/Conversations/components/chatElements/MessageVideo.vue';
import MessageSticker from '@/views/V2/Conversations/components/chatElements/MessageSticker.vue';
import MessageTemplateWhatsApp from '@/views/V2/Conversations/components/chatElements/MessageTemplateWhatsApp.vue';
import MessageRating from '@/views/V2/Conversations/components/chatElements/MessageRating.vue';
import MessageLocation from '@/views/V2/Conversations/components/chatElements/MessageLocation.vue';

export default {
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    bot: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    getImgBot: {
      type: String,
      default: '',
    },
    conversation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showIcon: -1,
      showMenuIndex: -1,
      counterbot: 0,
      counterClient: 0,
      messageToReply: null,
    };
  },
  components: {
    MessageText,
    MessageAlert,
    MessageCarousel,
    MessageFile,
    MessageImage,
    MessageOption,
    MessageAudio,
    MessageSticker,
    MessageRating,
    MessageTemplateWhatsApp,
    MessageVideo,
    MessageLocation
  },
  computed: {
    groupedMessages() {
      return this.messages.reduce((acc, el, index) => {
        let group =
          acc.length > 0 && acc[acc.length - 1].from === el.from
            ? acc[acc.length - 1]
            : null;
        if (group) {
          group.grouped.push(el);
        } else {
          acc.push({
            from: el.sender,
            grouped: [el],
            date: el.created_at,
            id: el.id,
          });
        }
        return acc;
      }, []);
    },
    conversationMetadata() {
      return this.conversation.custom;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  methods: {
    validateFormatMessage(group, message) {
      if (group.from == 'Watson') {
        return message.message_type != 'option' &&
        !message.text.includes('Ahora estas conversando con');
      } return true;
    },
    getDynamicComponent(type) {
      let selectedComponent;
      if (type) {
        switch (type) {
          // case "referral":
          //   selectedComponent = Referral;
          //   break;
          case 'text':
            selectedComponent = MessageText;
            break;
          case 'audio':
            selectedComponent = MessageAudio;
            break;
          case 'file':
            selectedComponent = MessageFile;
            break;
          case 'image':
            selectedComponent = MessageImage;
            break;
          // case "video":
          //   selectedComponent = MessageVideo;
          //   break;
          case 'sticker':
            selectedComponent = MessageSticker;
            break;
          case 'template_wtsp':
            selectedComponent = MessageTemplateWhatsApp;
            break;
          case 'location':
            selectedComponent = MessageLocation;
            // case "agent_comment":
            //   selectedComponent = AgentComment;
            break;
          default:
            break;
        }
      }
      return selectedComponent;
    },
    getMessageByPlatformId(mid) {
      return this.messages.find((el) => {
        return el.mid === mid;
      });
    },
    messageTime(date) {
      return formatDateRelative(date);
    },
    replyToMessage(message) {
      console.log('Replying to message', message);
      this.showMenuIndex = -1;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showMenuIndex = -1;
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss">
.message-owner,
.messageFromYou {
  margin: 0.25rem 0;
  animation-name: fade;
  animation-duration: 0.25s;
}
.messageFromYou {
  margin: 0.25rem 0;
  text-align: right;
}
.from-client {
  max-width: 70%;
  margin-bottom: 0.5rem;

  .message-inner {
    display: inline-block;
    background-color: #fafafa;
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    width: fit-content;
    overflow-x: hidden;
    border: 1px solid #cdcdcd;
    position: relative;

    pre p {
      margin-bottom: 0;
      position: relative;
      white-space: pre-wrap;
      word-break: break-word;
      color: #181818;

      a {
        color: #2981ef;
        word-break: break-word;
      }
    }
    .text_xs {
      // text-align: right;
      color: #181818;
    }
  }
  .bg_img {
    object-fit: cover;
    object-position: center;
    background-color: transparent;
    border-radius: 0 10px 10px 10px;
    border: 1px solid #f2f2f2;
    width: 100%;
    margin-top: 0.5rem;
    height: auto;
    max-width: 200px;
    max-height: 150px;
    overflow: hidden;
  }
}
.from-agent {
  width: 70%;
  text-align: right;
  margin-bottom: 0.5rem;

  .message-inner {
    width: auto;
    display: inline-block;
    border: 1px solid #bfdbff;
    background-color: #eff6ff;
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: 0;
    max-width: 100%;

    pre p {
      position: relative;
      white-space: pre-wrap;
      text-align: left;
      padding: 0;
      background-color: transparent;
      color: #181818;
      margin: auto auto 0;
      word-break: break-word;
    }
    .text_xs {
      color: #181818;
    }
  }
  .bg_img {
    object-fit: cover;
    object-position: center;
    background-color: transparent;
    border-radius: 10px 0 10px 10px;
    border: 1px solid #f2f2f2;
    width: 100%;
    margin-top: 0.5rem;
    height: auto;
    max-width: 200px;
    max-height: 150px;
    overflow: hidden;
  }
}
.profile-client {
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50rem;
  background-color: #2981ef;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}
.profile-wsp {
  background-color: #9edd63;
}
.profile-instagram {
  background-color: #f5365c;
}
.profile-bot {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50rem;
  object-fit: cover;
  object-position: center;
  border: 1px solid #f2f2f2;
}
.desktop-xs {
  @media (max-width: 480px) {
    display: none;
  }
}
.space-left {
  margin-left: 55px;

  @media (max-width: 480px) {
    margin-right: 0;
  }
}
.space-right {
  margin-right: 55px;

  @media (max-width: 480px) {
    margin-right: 0;
  }
  @media (max-width: 480px) {
    margin-right: 0;
  }
}
.rich_element {
  max-width: 100% !important;
}

.icon-row {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.message-menu {
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.message-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.message-menu ul li:hover {
  background-color: #f0f0f0;
}
</style>
